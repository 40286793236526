
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model';
import Chart from 'chart.js/auto'

@Options({
    components: {
    }
})
export default class ChartVue extends Vue {

    @Prop() summaryChartVM : OM.SummaryChartVM;

    chartSectionsNumber: number = 0;
    minPercentage: number = 0;
    maxPercentage: number = 0;

    data = null;
    config = null;
    chartRef: any = null;
    chart: any = null;

    created(){
    }

    mounted() {
        this.init();
        this.chart.resize(1000, 1000)
    }

    init(){
        this.chartRef = this.$refs.chart as HTMLElement;
        this.chartSectionsNumber = this.summaryChartVM.chartSections.length;

        if(this.summaryChartVM.chartSections.length == 0) {
            this.minPercentage = 0;
            this.maxPercentage = 0;
        }
        else 
        {
            this.minPercentage = this.summaryChartVM.chartSections.reduce((prev, curr) => {
                return prev.percentage < curr.percentage ? prev : curr;
            }).percentage;
            
            this.maxPercentage = this.summaryChartVM.chartSections.reduce((prev, curr) => {
                return prev.percentage > curr.percentage ? prev : curr;
            }).percentage;
        }

        var labels = this.summaryChartVM.chartSections.map( x => x.sectionName + " (" + x.percentage.toFixed(2) + "%)");
        var dataPercentage = this.summaryChartVM.chartSections.map( x => x.percentage);
        var colors = this.summaryChartVM.chartSections.map( x => x.colorHex);

        this.data = {
            labels: labels,
            datasets: [
                {
                    data: dataPercentage,
                    backgroundColor: colors,
                }
            ]
        }
        this.config = {
            type: 'pie',
            data: this.data,
            options: {
                borderWidth: 0,
                responsive: true,
                maintainAspectRatio: true,
                onResize: () => {
                    // view.chartWidth = view.chartContainer.offsetWidth;

                    // if(view.chart)
                    //     view.chart.resize(600, view.chartWidth);
                },
                plugins: {
                    legend: {
                        position: 'right',
                        labels: {
                            usePointStyle: true,
                            boxWidth: 8,
                            boxHeight: 8
                        }
                    },
                    tooltip: {
                        callbacks: {
                            label: function(context) {
                                var label: string = context.label;
                                var value = context.parsed;

                                var formattedLabel = label.substring(0, label.indexOf("(")).trim()

                                return " " + formattedLabel + ": " + value.toFixed(2) + "%";
                            }
                        }
                    },
                    title: {
                        display: false,
                    }
                }
            },
        };

        this.chart = new Chart(
            this.chartRef,
            this.config
        );
    }

}
