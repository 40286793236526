
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import chart from '@/views/calculator/components/chart.vue';
import * as OM from '@/Model';

@Options({
    components: {
        chart
    }
})
export default class SummaryCharts extends Vue {

    @Prop() model: OM.SummaryVM;

    created(){}

}
